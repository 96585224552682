<!--
 * @Author: yangxin 396270694@qq.com
 * @Date: 2024-11-12 17:01:40
 * @LastEditors: yangxin 396270694@qq.com
 * @LastEditTime: 2024-11-13 18:33:58
 * @FilePath: \jzjd-web-pc\src\views\data\tempIndex.vue
-->
<template>
  <div class="tempMain">
    <div class="wrap-box">
      <h1>精准监督</h1>
      <h2>（机关内部管理）</h2>
<!--      <div>-->
<!--        &nbsp;&nbsp; &nbsp;&nbsp;  从2021年起，通过开展大数据精准监督，<span>转变机关工作作风，激励干部担当作为，</span>稳步推进基地建设等各项任务落地见效。-->
<!--    </div>-->
      <div>
<!--        &nbsp;&nbsp;  &nbsp;&nbsp;  2024年，共落实任务<span>38652</span>次，平均每天落实<span>185.8</span>次，-->
<!--        最多一天<span>414</span>次。任务按时完成率由两年前<span>86%</span>上升到<span>100%</span>。累计数据<span>378775</span>条。-->
<!--    <br/>-->

        &nbsp;&nbsp; &nbsp;&nbsp; 机制：为了解决机关慢、粗、虚，建立<span>大数据精准监督+绩效考核+蜗牛奖</span>机制，转变机关工作作风，激励干部担当作为，推进各项任务落地见效。<br/>
        &nbsp;&nbsp; &nbsp;&nbsp; 今年以来，落实任务<span>3.8万次</span>，
        平均<span>落实185次/天</span>，最多<span>414次/天</span>，任务完成率<span>100%</span>，累计落实任务<span>11.6万</span>次。
      </div>

    </div>
    <div class="wrap-box" style="top:605px">
      <h1>农牧大数据</h1>
      <h2>（辅助领导决策指挥）</h2>
    <div> &nbsp;&nbsp; &nbsp;&nbsp;  功能：打通<span>数据孤岛—</span>，推进<span>数据资源共享</span>。系统横向打通类产业集群，
      纵向贯通智慧农牧业，与<span>350多个园区</span>、<span>2077家龙头企业</span>，以及<span>1.9万个市场主体</span>进行对接。<br/>
      &nbsp;&nbsp; &nbsp;&nbsp;目标：为全区农牧系统以及自治区党委政府领导决策指挥，提供数据支撑。
    </div>
    </div>
    <div class="wrap-box" style="width: 400px;left:calc( 100vw - 450px);">
      <h1>挂图作战</h1>
      <h2>（乡村振兴战略考核）</h2>
      <div>
        &nbsp;&nbsp; &nbsp;&nbsp; 为了加强调度，督促指导，开展“乡村振兴战略考核”<span>挂图作战</span>：<span>调度、约谈、问题、举措、风险点、布局图、机制和国考8个模块</span>。<br/>
        &nbsp;&nbsp; &nbsp;&nbsp; 厅内业务处室局中心，共享信息化系统，<span>实时联动盟市、旗县市区</span>。
      </div>
    </div>

    <div class="wrap-box" style="width: 400px;left:calc( 100vw - 450px);top:605px">
      <h1>系统架构</h1>
      <div>
        &nbsp;&nbsp; &nbsp;&nbsp; 电脑PC端+展示大屏+手机端（领导端），<span>汇集3000多个平台，2亿余条数据</span>。
      </div>
    </div>
	<div class="container">
		<!--	圆心-->
		<div class="ellipse">
        <div class="circle" @mouseenter="onMouseGo" @mouseleave="onMouseMove" @click="getToEdit('乡村')" style="animation-delay:-5s"></div>
        <div class="circle" @mouseenter="onMouseGo" @mouseleave="onMouseMove"  @click="getToEdit('农牧')" style="animation-delay:-15s"></div>
        <div class="circle" @mouseenter="onMouseGo" @mouseleave="onMouseMove"  @click="getToEdit('监督')" style="animation-delay:-25s"></div>
    </div>
	</div>
  </div>
</template>

<script>

	// items[0].style['animation-delay'] = '-5s,0s,0s';
	// items[0].querySelector('.item__circle').style['transform'] = 'scale(1.5)';
	//

import {mapActions, mapGetters} from 'vuex'

export default {
  mounted(){
  //   	var items = document.querySelectorAll('.circle');
  //   for (var i = 0; i < items.length; i++) {
  //     (function (i) {
  //       items[i].style['animation-delay'] = (-5) - 4 * i + 's,' + (-4 * i) + 's,' + (-4 * i) + 's'
  //     })(i);
	// }
  },
  methods:{
    onMouseGo() {
		let items = document.querySelectorAll('.circle')

		for (var i = 0; i < items.length; i++) {
				items[i].classList.add('active')
		}
	},
	onMouseMove() {
		let items = document.getElementsByClassName('circle')
		for (var i = 0; i < items.length; i++) {
			items[i].classList.remove('active')
		}
	},
  getToEdit(name){
    this.setindexTempType(true)
    this.$parent.url1 = require("@/assets/images/data/cy.mp4")
    this.$parent.$refs.videoRef.load();
    this.$parent.getSelectData(name)

  },

  ...mapActions({
    departmentsDataUpdate:'schema/DepartmentsDataUpdate',
    setindexTempType:"setindexTempType"
    }),
  }
}
</script>

<style lang="scss" scoped>
// 设置大小
$ellipse:400px;


//
$circle:$ellipse/10;
@mixin style($mod) {
    width: $mod * 2;
    height: $mod * 2;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
}

/*鼠标进入停止*/
.active {
  animation-play-state: paused !important;
}
.ellipse {
    @include style($ellipse);
    border: 4px solid rgba($color: #dfc459, $alpha: 0.7) ;
    transform-style: preserve-3d;
    transform:  rotateX(70deg);

    .circle {
        @include style($circle);
        width: 206px;
        height: 555px;
        animation: move linear infinite;

        // animation-name: animX,animY;
          // animation-iteration-count: infinite;
        animation-duration: 30s;
          /*动画执行一次所需时间*/
  // animation-duration: 10s, 10s, 20s;
  /*动画以何种运行轨迹完成一个周期*/
  // animation-timing-function: cubic-bezier(0.36,0,0.64,1);
        z-index: 3;
        &:nth-child(1){
            background-image: url("../../assets/images/xiangchun.png");
            background-size: 100% 100%;
        }

        &:nth-child(2){
            background-image: url("../../assets/images/nongmu.png");
            background-size: 100% 100%;
        }
        &:nth-child(3){
            background-image: url("../../assets/images/jiandu.png");
            background-size: 100% 100%;
        }
    }

}

@keyframes scale {

  0% {
    transform: scale(0.4);
    z-index: 0
  }
  50% {
    transform: scale(1);
    z-index: 3
  }
  100% {
    transform: scale(0.5);
    z-index: 0
  }
}

/*第二层*/
@keyframes animX{
  0% {
    left: -500px;
  }
  100% {
    left: 500px;
  }
}
@keyframes animY{
  0% {
    top: 70px;
  }
  100% {
    top: 200px;
  }
}
@keyframes move {
    0%  {
        transform: rotateZ(0) translateX($ellipse) rotateZ(0);
    }
    // 25%{
    //   transform: rotateZ(-50deg) translateX($ellipse);
    // }
    // 50%{
    //   transform: rotateZ(-180deg) translateX($ellipse) rotateZ(360deg) (1.5);
    // }
    100% {
        transform: rotateZ(-360deg) translateX($ellipse) rotateZ(360deg);
    }
}
.wrap-box{
  position: absolute;
  width: 500px;
  // height: 430px;
  left: 50px;
  top:110px;
  background: rgba(0, 0, 0, .3);
  padding:10px 20px;
  h1,h2{
    text-align: center;
    margin-bottom: 10px;

  }
  h1{
    font-size: 36px;
  }
  div{
    font-size: 24px;
    color: #fff;
    line-height: 38px;
    margin-bottom: 10px;
    span{
      color: #ffdd00;
      font-size: 30px;
    }
  }
}
</style>
