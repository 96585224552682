import { getDeptTree } from "@/api/user";
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import home from "../mock/home/home.json"
export const busMixin = {
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userName: state => state.userInfo.name,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
    ...mapGetters({
      getSchema: 'schema/schema',    // 将 store.getters.count 映射到 this.count
    })
  },
  data() {
    return {
      isAdmin:false,
      showGoHome:false,
      depTree: { children: [] },
      title: "",
      allDataSources:[]
    };
  },

  methods: {
    ...mapMutations({
      setSchema: 'schema/SET_SCHEMA',  // 将 store.commit('increment') 映射为 this.increment
    }),
    ...mapActions({
      departmentsDataSelect:"schema/DepartmentsDataSelect",
      loadDataSource:'schema/LoadDataSource',
    }),

    async busMain() {

      try {
        // 等待获取用户信息并设置部门树
        this.depTree = await this.fatchUserInfo();
        console.log(this.userDepartmentName,this.userId)
        console.log(this.depTree )

        if (this.depTree.id == 1 ){

           this.title= ""
        }else{
          this.title = "(" + this.depTree.name + ")"
        }


        await this.requestTheCurrentDepData(this.depTree.name)



      } catch (error) {
        console.error('Failed to fetch busMain info:', error);
      }
    },

    async fatchUserInfo() {
     
      // 如果是领导或特定用户，则获取部门列表，否则返回当前部门名称
      if (this.userDepartmentName === '厅领导' || this.userId === 188) {
        this.isAdmin = true;
        // 获取部门列表并返回
        return await this.fetchDepList();
      } else {
        // 返回当前部门名称
       
        return { name: this.userDepartmentName, id:this.userDepartmentId,children: [] };
      }
    },

    async fetchDepList() {
      try {
        // 发起请求并返回数据
        const response = await getDeptTree();
        return response;
      } catch (error) {
        console.error('Failed to fetch department list:', error);
        return { children: [] }; // 返回一个空的部门树作为 fallback
      }
    },
    async  getNodeDataByDepName(name) {

     return await this.departmentsDataSelect({
        "departments_name":name,
        "id":this.userId
      }).then(res=>{
        return res
      })
      //return home[name]||home['home'];
    },
    findDataSources(node) {
     
      if (typeof node === 'object' && node !== null) {
        for (let key in node) {
         
          if (key === 'dataSources' && node[key].name) {
            // 提取 name 和 params 属性，如果 params 为空则设置为默认值（例如空对象）
            const { name, params = {} } = node[key];
            // 组装成新对象
            const newObject = { name, params };
            // 将新对象添加到 allDataSources 数组中
       
            this.allDataSources.push(newObject);
          }
          // 递归查找子节点
          this.findDataSources(node[key]);
        }
      }
    },

    //切换部门
   async switchDepartment(name) {
    
      await this.requestTheCurrentDepData(name)
      if(name == "home"){
        name = "内蒙古自治区农牧厅"
      }
     if (name =="home"  ||  name =="内蒙古自治区农牧厅"){
       this.title= ""
     }else{
       this.title = "(" + name + ")"
     }

     this.depTree.name=name
      //返回首页逻辑显示
      this.showGoHome = !this.showGoHome;
      this.dialogTableVisible = false
     
    },


    async requestTheCurrentDepData(depName) {
     

      let currentNodeData = await this.getNodeDataByDepName(depName);
      console.log(currentNodeData)
      
      //提交 vuex 中
      this.setSchema(currentNodeData);
      this.allData = currentNodeData.value

      //如果 结果中有 数据源绑定,则查询数据源
      this.findDataSources(currentNodeData);
  
      //数据源进行数据查询
     
      this.allDataSources.forEach(dataSource=>{
        //调用 vuex 填充数据源的数据
      
        this.loadDataSource(dataSource).then( res =>{
          console.log("vuex load 成功:")

        });

      })
    }
  },
};
