<script>
// import 'vue-video-player/src/custom-theme.css'
import hlsjs from 'hls.js'

export default {


  props: {
    vodeoList: {
      type: Array,
      default: () => {
        return []
      },
    },
    cardData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      videoUrl: 'https://v7.tlkqc.com/wjv7/202408/08/448Gh0JTJv79/video/1000k_720/hls/index.m3u8',
    }
  },

  mounted() {
    this.videoPlay()
  },
  computed: {
    dynamicStyles() {
      return function (data) {
        if (this.cardData.imgType == 'pic-list') {

          return `width:${this.cardData.imgwidth}px;height:${this.cardData.imgheight}px;`
        }
      }
    }
  },

  methods: {
    videoPlay() {
      this.video = this.$refs.videoAug //定义挂载
      if (hlsjs.isSupported()) {
        this.hlsjs = new hlsjs()
        this.hlsjs.loadSource(this.videoUrl) //加载播放地址
        this.hlsjs.attachMedia(this.video) //解析到video标签上
        this.hlsjs.on(hlsjs.Events.MANIFEST_PARSED, () => {
          this.video.play() //开始播放
          console.log('加载成功')
        })
        this.hlsjs.on(hlsjs.Events.ERROR, (event, data) => {
          // 监听出错事件
          console.log('加载失败')
        })
      } else {
        console.log('不支持的格式')
        return
      }
    },
    nextVideo(index) {
      if (index + 1 == this.vodeoList.length) {
        index = -1
      }
      this.$refs.carouse.setActiveItem(index + 1);
      this.$refs[`video${index + 1}`][0].currentTime = 0;
      this.$refs[`video${index + 1}`][0].play();

    }
  },
}
</script>

<template>
  <div class="videoMain">
    <div class="video-grid" v-if="cardData.imgType == 'pic-list'">
      <div class="video-item" v-for="(item, index) in vodeoList" :key="index">
        <div :style="dynamicStyles(item)">
          <video width="100%" height="100%" muted controls ref="videoAug">
            <source type="video/mp4" :src="item.url" />
          </video>
        </div>

        <div class="title" :style="{ 'width': cardData.imgwidth }">{{ item.title }}</div>
      </div>
    </div>
    <!-- 轮播视频 -->
    <div class="carousel-box" v-else>
      <el-carousel indicator-position="none" :interval="cardData.carouselTime * 1000" :autoplay='false' ref="carouse">
        <el-carousel-item v-for="(item, index) in vodeoList" :key="index"  :style="{alignItems:cardData.imgSize=='self-adapt'?'baseline':'center'}">
          <div
            :style="{ width: cardData.imgSize == 'self-adapt' ? '100%' : cardData.imgwidth + 'px', height: cardData.imgSize == 'self-adapt' ? '93%' : cardData.imgheight + 'px' }">
            <video width="100%" height="100%" muted controls ref="videoAug" autoplay @ended="nextVideo(index)"
              :ref="`video${index}`">
              <source type="video/mp4" :src="item.url" />
            </video>
            <div class="carousel-title">{{ item.title }}</div>
          </div>

        </el-carousel-item>
      </el-carousel>

    </div>
  </div>
</template>



<style scoped lang="scss">
.videoMain {
  height: 100%;
  /* 根据需要调整高度 */
  width: 100%;
  overflow-y: scroll;
}

.videoMain::-webkit-scrollbar {
  margin-top: 3px;
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 16px;
}

.videoMain::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 217, 255, .7);
  z-index: 1;
}

.video-grid {
  // display: grid;
  // grid-template-columns: repeat(2, 2fr); /* 创建两列 */
  // gap: 10px; /* 设置网格项之间的间隙 */
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  /* 确保视频在其容器内保持其宽高比 */
  position: relative;
  // width: 100%;
  /* 宽度填满列 */
  // padding-top: 56.25%; /* 假设视频宽高比为16:9，这里用百分比设置高度 */
  // overflow: hidden;
  margin-bottom: 10px;
  margin-right: 10px;
  // float: left;
  border: 1px solid rgba(0, 224, 219, 0.3);
  border-radius: 9px;
  background: rgba(0, 62, 92, 0.3);
  margin: 10px 6px 6px 6px;
  padding-bottom: 6px;

  .title {
    font-size: 18px;
    color: #fff;
    border-radius: 0 0 9px 9px;
    line-height: 27px;
    padding: 2px 10px 0 10px;
    font-size: 16px;
    // background-color: rgba(255, 255, 255, 0.1);
    // backdrop-filter: blur(2px);
    // -webkit-backdrop-filter: blur(2px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    // box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
    // -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;

    display: -webkit-box;
    /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical;
    /* 垂直排列 */
    -webkit-line-clamp: 2;
    /* 限制显示两行 */
    overflow: hidden;
    /* 隐藏超出范围的内容 */
    text-overflow: ellipsis;
    /* 使用省略号 */
    width: 230px;
  }
}

.video-item video {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
  /* 保持视频的宽高比，同时填满容器 */
}

.video {
  // height: calc(70vh - 260px); /* 根据需要调整高度 */
  padding: 2px;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #014e5e #ffffff00;
  /* For Firefox */

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #014e5e;
    border-radius: 2px;
    border: 1px solid #ffffff;
    /* Adjust the border color if needed */
  }
}

.carousel-box {
  width: 100%;
  height: 100%;

  /deep/ .el-carousel__container {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-carousel {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-carousel__item {
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel-title {
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap; /* 确保文本在一行内显示 */
      overflow: hidden; /* 隐藏溢出的内容 */
      text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
      width: 96%;
    }
  }

  /deep/ .el-carousel__arrow {
    background-color: rgba(31, 45, 61, 0.81);
  }
  video{
    object-fit: cover;
  }
}
</style>
