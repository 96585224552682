<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
  <el-dialog title="编辑模块" :append-to-body="true" :lock-scroll="false" modal :close-on-click-modal="false"
    @close="closeFunc" :visible="imagesEidtDialogType" top='5vh'>

    <el-card class="box-card">
      <div class="card-item">
        <span class="label">展示形式</span>
        <el-radio-group v-model="imgType">
          <el-radio label="pic-list">图片列表形式</el-radio>
          <el-radio label="carousel">轮播图形式</el-radio>
        </el-radio-group>
        <div  v-if="imgType == 'carousel'">
          <span class="label" style="margin-left: 50px;">轮播时间</span>
          <el-input-number v-model="carouselTime" :min="0" :max="10"></el-input-number>
        </div>
      </div>
      <div class="card-item">
        <span class="label">图片尺寸</span>
        <el-radio-group v-model="imgSize">
          <el-radio label="self-adapt">{{imgType=='pic-list'?'默认尺寸':'自适应尺寸'}}</el-radio>
          <el-radio label="self-custom">自定义尺寸</el-radio>
        </el-radio-group>
        <div class="setSize" v-if="imgSize == 'self-custom'">
          <div>宽 <el-input-number v-model="imgwidth" :min="250" :max="3900"></el-input-number> px</div>
          <div>高 <el-input-number v-model="imgheight" :min="250" :max="3900"></el-input-number> px</div>
        </div>
      </div>
      <div class="card-item">
        <el-button @click="saveCard" type="primary">保 存</el-button>
      </div>
    </el-card>


    <div class="editMain">
      <div class="menuList">
        <div class="navBox" @click="addItemFunc">
          + 添加{{ dragItemName }}
        </div>
        <div class="bttomBox">
          <div v-for="(item, index) in imgList" :key="index" class="itemBox"
            :class="{ 'itemBox2': editListMode.id === item.id }" @click="getItemFunc(item, index)">
            <img style="height: 100%;width: 100%;" :src="item?.url" alt="" v-if="dragItemName == '图片' && item.url">
            <div class="topMenu" v-show="editListMode.id === item.id">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <el-button>编辑</el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onTheMove(item, index)">上移动</el-dropdown-item>
                  <el-dropdown-item @click.native="underTheMove(item, index)">下移动</el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="delBlockItemFunc(index)">删除</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="editMenu">
        <el-form v-if="imgList.length" label-width="120px" :model="editListMode" style="height: 100%" ref="imgFromList"
          :rules="rules">
          <el-form-item label="标题名称" prop="title">
            <el-input v-model="editListMode.title"></el-input>
          </el-form-item>
          <!-- <el-form-item label="展示形式" prop="imgType">
            <el-radio-group v-model="editListMode.imgType">
              <el-radio label="pic-list">图片列表形式</el-radio>
              <el-radio label="carousel">轮播图形式</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <!-- <el-form-item label="图片尺寸" prop="imgSize">
            <el-radio-group v-model="editListMode.imgSize">
              <el-radio label="self-adapt">自适应尺寸</el-radio>
              <el-radio label="self-custom">自定义尺寸</el-radio>
            </el-radio-group>
            <div class="setSize" v-if="editListMode.imgSize=='self-custom'">
               <div>宽 <el-input-number v-model="editListMode.imgwidth"  :min="250" :max="3900"></el-input-number> px</div>
               <div>高 <el-input-number v-model="editListMode.imgheight"  :min="250" :max="3900"></el-input-number> px</div>
            </div>
            
            
          </el-form-item> -->
          <!-- <el-form-item label="轮播时间" prop="carouselTime" v-if="editListMode.imgType=='carousel'">
            <el-input-number v-model="editListMode.carouselTime"  :min="0" :max="10"></el-input-number>
          </el-form-item> -->
          <el-form-item label="是否含外链" prop="linkType" v-if="dragItemName == '图片' &&imgType == 'pic-list'">
            <el-radio-group v-model="editListMode.linkType">
              <el-radio label="noLink">不含外链</el-radio>
              <el-radio label="built-in">内置网页</el-radio>
              <el-radio label="web-view">内嵌网页</el-radio>
              <el-radio label="JumpImg">跳转网页</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="外链地址" prop="linkUrl"
            v-if="(editListMode.linkType == 'JumpImg' || editListMode.linkType == 'web-view') && dragItemName == '图片'">
            <el-input v-model="editListMode.linkUrl"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" v-if="dragItemName == '图片'">
            <el-input-number v-model="editListMode.sort" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="封面图" prop="linkType">
            <el-upload class="upload-demo" style="height: 100px;width: 200px;" drag action="" :show-file-list="false"
              :http-request="onUpload">
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

            </el-upload>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-form-item>
          <el-form-item label="文字内容:" prop="content" v-if="editListMode.linkType == 'built-in' && dragItemName == '图片'">
            <!-- <Toolbar ref="toolbar" style="border: 1px solid #ccc" :editor="editor" :default-config="toolbarConfig" :mode="mode" /> -->
            <Editor style="height: 200px; overflow-y: hidden;border: 1px solid #DCDFE6;" v-model="editListMode.content"
              :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
          </el-form-item>
          <el-form-item label="图片上传" prop="imgList" v-if="editListMode.linkType == 'built-in' && dragItemName == '图片'">
            <c-upload-image v-model="editListMode.imgList"></c-upload-image>
          </el-form-item>
          <el-form-item label="视频上传" prop="mediaList"
            v-if="editListMode.linkType == 'built-in' && dragItemName == '图片'">
            <c-upload-media v-model="editListMode.mediaList" :default="videoFileList"
              :acceptList="['audio/mp4', 'video/mp4', 'audio/mpeg',].join(',')" flag="1"></c-upload-media>
          </el-form-item>
          <el-form-item>
            <el-button @click="savefunc()">保存</el-button>

            <el-button style="margin-left: 30rpx" @click="delBlockItemFunc">
              删除
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { uploadImage, uploadMedia } from '@/api/common/upload'
import pdf from 'vue-pdf'
import VideoPlayer from "../vueVideoPlayer/videoPlayer"
import "@wangeditor/editor/dist/css/style.css"
import CUploadImage from "@/components/upload/image.vue";
import CUploadMedia from "@/components/upload/video.vue";
import EditorTextbus from "@/components/editor/textbus/index.vue";
import { nanoid } from "nanoid";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { createPdfs, deletePdfs, updatePdfs } from '@/api/news/index'
export default {
  components: { Editor, Toolbar, VideoPlayer, pdf, CUploadImage, EditorTextbus, CUploadMedia },
  props: {
    cardIndex: {
      type: Object,
      default: () => ""
    },
    imagesEidtDialogType: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {

      editListMode: {
        id: 0,
        title: "",
        src: "",
        linkUrl: '',
        sort: 1,
        linkType: 'noLink'
      },
      cardList: "",
      cardChild: "",
      textItemList: [],
      editor: null,
      toolbarConfig: {},
      mapping: {},
      mediaList: {},
      editor: null,
      toolbarConfig: {
        toolbarKeys: [
          'bold',
          'underline',
          'italic',
          'through',
          'clearStyle',
          'color',
          'bgColor',
          'fontSize',
          'justifyLeft',
          'justifyRight',
          'justifyCenter',
          'justifyJustify',
          'lineHeight',
          'header1',
          'header2',
          'header3',
          'header4',
          'header5',
          'bulletedList',
          'numberedList',
          // 'uploadImage'
          // "uploadVideo"
        ]
      },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      nameType: "",
      imgList: [],
      linkBtnList: [
        { id: 1, name: '不含外链' },
        { id: 2, name: '内嵌网页' },
        { id: 3, name: '跳转网页' },
      ],
      videoFileList: [],
      FileList: [],
      // 表单校验
      rules: {
        linkUrl: [
          {
            validator: (rule, value, callback) => {
              console.log(value.indexOf("https://"))
              if (value === '') {
                callback(new Error('请输入域名地址'));
              } else if (value.indexOf("http") == -1) {
                callback(new Error('请开头输入https://或者http://'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
      },
      chooseindex: null,
      postId: '',//当前id
      imgType: 'pic-list',//展示形式
      imgSize: 'self-adapt',//图片尺寸
      carouselTime: 1,//；轮播时间
      imgwidth: '250',
      imgheight: '250'
    }
  },
  mounted() {
    this.init()
 
  },

  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userName: state => state.userInfo.name,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
    dragItemName() {

      return this.$parent.dragItemName?.name
    },
    /**
     * @Author: YangXin
     * @Description: 从store获取元素
     * @Date: 2024-08-07 18:23:53
     * @return {*}
     */
    imgTextList() {
      return this.$store.getters.imgTextList;
    },
    currentDepartment() {
      return this.$store.getters["schema/schema"].value
    },
    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey',    // 将 store.getters.count 映射到 this.count
      getData: 'schema/dataMap'
    })

  },


  methods: {
    /**
     * @Author: YangXin
     * @Description: 初始化获取修改的数组
     * @Date: 2024-08-07 18:18:03
     * @return {*}
     */
    init: function () {

      // this.dragItemName = this.$parent.dragItemName?.

      if (this.cardIndex) {

        this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
          this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
      } else {
        this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
          this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
      }
    
      this.imgType = this.cardChild.imgType
      this.carouselTime = this.cardChild.carouselTime
      this.imgSize = this.cardChild.imgSize
      this.imgwidth = this.cardChild.imgwidth
      this.imgheight = this.cardChild.imgheight
      if (this.cardChild.children) {
        if (this.cardChild.children[0].type == "img") {
          this.$parent.dragItemName = { name: "图片" }
          this.imgList = this.cardChild.children[0].imgList
          this.videoFileList = this.imgList[0].mediaList.length > 0 ? this.imgList[0].mediaList : []
          // this.imgList.forEach(element => {
          //   if(element.linkType=='built-in'){
          //     this.videoFileList = element.mediaList
          //   }
          // });
        }
        this.editListMode = this.imgList[0]
      } else {
        this.imgList = []
      }
      
    },

    /**
     * @Author: YangXin
     * @Description: 添加图片
     * @Date: 2024-08-07 18:21:32
     * @return {*}
     */
    addItemFunc: function () {
      if (this.dragItemName == "图片") {
        this.imgList.unshift({
          type: "img",
          id: this.imgList.length,
          title: "",
          linkUrl: null,
          saveType: false,
          sort: 1,
          linkType: 'noLink',
          imgList: [],
          mediaList: [],
          url: '',
          
        })
      }

      // if (this.imgList.length == 1) {
      this.editListMode = this.imgList[0]

      // }

    },
    /**
     * @Author: YangXin
     * @Description: 上移动
     * @Date: 2024-08-15 14:52:12
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    onTheMove: function (item, index) {
      this.imgList.splice(index - 1, 0, item)
      this.imgList.splice(index + 1, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 下移动
     * @Date: 2024-08-15 14:52:33
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    underTheMove: function (item, index) {
      this.imgList.splice(index + 2, 0, item)
      this.imgList.splice(index, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 删除元素
     * @Date: 2024-08-15 14:58:00
     * @param {*} index
     * @return {*}
     */
    delBlockItemFunc: function () {
      let item = this.editListMode
    
        this.imgList.splice(this.chooseindex, 1)
        if (this.imgList.length !== 0) {
          this.editListMode = this.imgList[0]
        }
      

    },
    /**
     * @Author: YangXin
     * @Description: 上传图片
     * @Date: 2024-08-13 16:32:47
     * @param {*} data
     * @return {*}
     */
    onUpload: function (data) {
      if (this.dragItemName == "图片") {
        uploadImage(data.file, progress => {
          if (progress.total > 0) {
            progress.percent = (progress.loaded / progress.total) * 100
          }
          data.onProgress(progress)
        }).then(res => {
          this.editListMode.url = res.image_url,

            this.$message({ message: '上传成功', type: 'success' });
        })
      }
    },
    // 上传图标
    IcononUpload(data) {
      uploadImage(data.file, progress => {
        if (progress.total > 0) {
          progress.percent = (progress.loaded / progress.total) * 100
        }
        data.onProgress(progress)
      }).then(res => {
        this.editListMode.icon = res.image_url,

          this.$message({ message: '上传成功', type: 'success' });
      })
    },
    /**
     * @Author: YangXin
     * @Description: 上传图片回显
     * @Date: 2024-08-07 18:18:07
     * @param {*} res
     * @param {*} file
     * @return {*}
     */
    handleAvatarSuccess(res, file) {
      this.editListMode.images = URL.createObjectURL(file.raw);
    },
    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-07 18:18:12
     * @param {*} editor
     * @return {*}
     */
    onCreated(editor) {
      this.editor = Object.seal(editor)
    },
    /**
     * @Author: YangXin
     * @Description: 点击获取修改的item
     * @Date: 2024-08-08 09:07:28
     * @param {*} item
     * @return {*}
     */
    getItemFunc: function (item, index) {

      this.editListMode = item
      this.videoFileList = item.mediaList
      this.chooseindex = index

    },
    // 保存图片卡片形式
    saveCard(){
     if(!this.cardChild.children){
      this.$message({
          message: '请添加图片',
          type: 'warning'
        });
        return;
     }
     this.cardChild.imgType = this.imgType
     this.cardChild.carouselTime = this.carouselTime
     this.cardChild.imgSize = this.imgSize
     this.cardChild.imgwidth = this.imgwidth
     this.cardChild.imgheight = this.imgheight
     this.$message({
          message: '保存成功',
          type: 'success'
        });
    },
    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-08 10:17:20
     * @return {*}
     */
    savefunc: function () {
      let item = null
      
      // if(this.cardChild.childre==null){
      //   this.cardChild.children = []
      // }


      this.imgList.map((res, index) => {
        if (res.id == this.editListMode.id && res.saveType == false) {
          this.imgList[index].saveType = true
          item = res
        } else {
          item = res
        }
      })
      if (item) {

        if (this.cardChild.children && this.cardIndex?.type !== true) {

          if (this.dragItemName == "图片") {
            // this.cardChild.children[0].imgList.push({
            //   id: item.id, title: item.title, url: item.url, linkUrl: item.linkUrl, sort: item.sort, linkType: item.linkType, imgList: item.imgList,
            //   mediaList: item.mediaList, content: item.content
            // })
            this.cardChild.children[0].imgList = this.imgList

          }

        } else if (this.cardChild.children == null || this.cardChild.children == '') {
          this.cardChild.children = []
          if (this.dragItemName == "图片") {
            // this.cardChild.children.push({
            //   "type": "img",
            //   imgList: [{
            //     id: item.id, title: item.title, url: item.url, linkUrl: item.linkUrl, sort: item.sort, linkType: item.linkType, imgList: item.imgList,
            //     mediaList: item.mediaList, content: item.content
            //   }]
            // })
            this.cardChild.children.push({
              "type": "img",
              imgList: this.imgList
            })

          }
        } else {
          //添加更新代码
        }

        let result = this.cardChild.children[0].imgList.sort(this.sortBy('sort'))
        this.cardChild.children[0].imgList = result
        
        this.$message({
          message: '保存成功',
          type: 'success'
        });

        // call(this)

      }

      // let item = this.getDataMapByKey(this.nameType)[this.editListMode.id]

      // item.title = this.editListMode.title
      // item.src = this.editListMode.images
      // item.isSave = true
    },

    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-08 10:41:22
     * @return {*}
     */
    closeFunc: function () {
      // console.log(this.cardChild.children)
      // this.imgTextList.data = this.textItemList
      // this.cardChild.children = [JSON.parse(JSON.stringify(this.imgTextList))]
      this.$parent.imagesEidtDialogType = false
    },
    sortBy(attr, rev) {
      //第二个参数没有传递 默认升序排列
      if (rev == undefined) {
        rev = 1;
      } else {
        rev = (rev) ? 1 : -1;
      }

      return function (a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 0;
      }
    }

  },


}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 80% !important;
  border-radius: 10px;
  background: #F0F0F0;


}

.upload-demo {
  /deep/ .el-upload {
    height: 100%;
    width: 100%;

    .el-upload-dragger {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  }
}


.titleBox {
  width: 100%;
  display: flex;
  padding-top: 10px;

}

// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #9c2b2b !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF !important;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.editMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .menuList {
    width: 30%;
    padding-top: 10px;
    padding: 10px;
    background: #ffffff;
    // border: 1px solid black;
    height: 825px;

    .navBox {
      cursor: pointer;
      height: 60px;
      width: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 8px;
      color: #0074AB;
      border: 1px solid #0074AB;
    }

    .navBox:hover,
    .itemBox:hover {
      background: #F0F0F0;
    }

    .bttomBox {
      padding-top: 10px;
      height: calc(100% - 60px);
      overflow-y: scroll !important;
      /* 或者 overflow-x: scroll; 根据需要选择 */
    }

    .itemBox {
      width: 100%;
      cursor: pointer;
      position: relative;
      height: 150px;
      padding: 5px 8px;
      border-radius: 4px;
      display: flex;
      border: 1px solid #0074AB;

    }

    .itemBox2 {
      background: #F0F0F0;

      // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
    }
  }

  .editMenu {
    padding-top: 10px;
    margin-left: 10px;
    background: #ffffff;
    height: 825px;
    width: 69%;
    padding: 14px 20px;


    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}

.topMenu {
  top: 0;
  right: 0;
  position: absolute;
}

.setSize {
  display: flex;
  margin-left: 10px;

  >div {
    margin-right: 10px;
  }
}
.box-card{
  margin-bottom: 15px;
  /deep/ .el-card__body{
   
  }
  
  .card-item{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .label{
      margin-right: 10px;
    }
    &:last-child{
      margin-bottom: 0;
      margin-top: 15px;
    }
  }
}
</style>
