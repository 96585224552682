<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
  <el-dialog
    title="编辑模块"
    :close-on-click-modal="false"
    :append-to-body="true"
    :lock-scroll="false"
    modal
    @close="closeFunc"
    :visible="urlEditDialogType"
  >
    <div class="editMain">
      <div class="menuList">
        <div class="navBox" @click="addItemFunc"> + 添加链接 </div>

        <div class="bttomBox">
          <div
            v-for="(item, index) in newsCategorysList"
            :key="index"
            class="itemBox"
            :class="{ itemBox2: editListMode.id === item.id }"
            @click="getItemFunc(item, index)"
          >
         
            
            <div style="width: 100%;" v-if="item.linkType=='web-view'">{{ item.url }}</div>
            <div style="width: 100%; " v-else>
              <img :src="item.imageUrl" alt="" style="width: 100%;height: 100%;" v-if="item.imageUrl">
            </div>

            <div style="height: 100%;position: relative">
         
              <div class="topMenu" v-show="editListMode.id === item.id">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <el-button>编辑</el-button>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="onTheMove(item, index)">上移动</el-dropdown-item>
                    <el-dropdown-item @click.native="underTheMove(item, index)">下移动</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="delBlockItemFunc(item, index)">删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="editMenu">
        <el-empty :image-size="200" style="height: 725px" v-if="!newsCategorysList.length"></el-empty>
        <el-form
          v-if="newsCategorysList.length"
          label-width="120px"
          :model="editListMode"
          style="height: 100%"
          ref="imgFromList"
          :rules="rules"
        >
          <el-form-item label="标题名称" prop="title">
            <el-input v-model="editListMode.title"></el-input>
          </el-form-item>
          <el-form-item label="链接方式: " prop="linkType">
            <el-radio-group v-model="editListMode.linkType">
              
              <el-radio label="web-view">内嵌网页</el-radio>
              <el-radio label="JumpImg">跳转网页</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="目标URL地址" prop="url">
            <el-input v-model="editListMode.url"></el-input>
          </el-form-item>
          <el-form-item label="图片" v-if="editListMode.linkType=='JumpImg'">
            <el-upload class="upload-demo" drag
            action="" :http-request="onUpload" 
          :show-file-list="false"  :limit="1"
            >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button @click="savefunc()">保存</el-button>

            <el-button
              style="margin-left: 30rpx"
              @click="
               delBlockItemFunc
              "
              >保存并关闭</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { mapGetters, mapActions } from 'vuex'
import { uploadImage } from '@/api/common/upload'
import { updateNews, getNewsDetail, createNews, deleteNews } from '@/api/news/index'

import '@wangeditor/editor/dist/css/style.css'
import CUploadImage from '@/components/upload/image.vue'
import EditorTextbus from '@/components/editor/textbus/index.vue'
export default {
  components: { EditorTextbus, CUploadImage, Editor, Toolbar },
  props: {
    cardIndex: {
      type: Object,
      default: () => {},
    },
    urlEditDialogType: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      category_id: '',
      column_id: '',
      editListMode: {
        title: '',
        url:"",
        linkType:'web-view',
        imageUrl:'',
        
      },
      addItemStatus: false,
      mapping: {},
      cardList: '',
      cardChild: '',
      newsCategorysList: [],
      editor: null,
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      nameType: '',
      radio: '',
      createName: '',
      postId: '',
      linkBtnList: [
        { id: 1, name: '内嵌网页' },
        { id: 2, name: '跳转网页' },
      ],
      // 表单校验
      rules: {
        url: [
          { validator: (rule, value, callback)=>{
            console.log(value.indexOf("https://"))
            if (value === '') {
              callback(new Error('请输入域名地址'));
            } else if (value.indexOf("http") == -1) {
              callback(new Error('请开头输入https://或者http://'));
            } else {
              callback();
            }
          }, trigger: 'blur' }
        ],
      },
      chooseIndex:null
    }
  },
  mounted() {
    this.init()
  },

  computed: {
    /**
     * @Author: YangXin
     * @Description: 从store获取元素
     * @Date: 2024-08-07 18:23:53
     * @return {*}
     */
    imgTextList() {
      return this.$store.getters.imgTextList
    },
    currentDepartment() {
      return this.$store.getters['schema/schema'].value
    },

    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey', // 将 store.getters.count 映射到 this.count
    }),
  },

  methods: {
    getImageSrc(src) {
      if (Array.isArray(src)) {
        return src.length > 0 ? src[0] : ''
      }
      return src
    },
    /**
     * @Author: YangXin
     * @Description: 初始化获取修改的数组
     * @Date: 2024-08-07 18:18:03
     * @return {*}
     */
    init: function () {
      
      if (this.cardIndex) {
        this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
          this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
      } else {
        this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
          this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
      }
      console.log(this.cardChild)
     
      if(this.cardChild.children){
    
      
      this.newsCategorysList = this.cardChild.children[0].linkList
   

      }

    

      if (this.newsCategorysList) {
        let item = this.newsCategorysList[0]
        this.editListMode = item

        // this.editListMode.images = []
        // this.editListMode.id = item.id
      } else {
        this.newsCategorysList = []
      }
    },

    /**
     * @Author: YangXin
     * @Description: 添加文章
     * @Date: 2024-08-07 18:21:32https://mmbiz.qpic.cn/sz_mmbiz_gif/9Z5K66tFPiaxOPEtebskspQauYR4ZAABgDkhnwgHmmpbHHjTYpFLoSqfvxjuGlQ12icJ1GgeWQFPuQYibPiaMlASmg/640?wx_fmt=gif&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1
     * @return {*}
     */
    addItemFunc: function () {
      this.newsCategorysList.unshift({
        id: this.newsCategorysList.length,
        title: '',
        url:'',
        linkType: 'web-view',
        saveType: false,
        type: "views",
        imageUrl:''
      })

      this.addItemStatus = true

      if (this.newsCategorysList.length == 1) {
        this.editListMode = this.newsCategorysList[0]
      }
      // let data = this.getDataMapByKey(this.nameType)
      // console.log(data)

      // createNews({category_id:this.cardIndex.id,...this.editListMode}).then((res)=>{
      //     getNewsPage(this.cardIndex.id).then((item)=>{
      //         console.log(item)
      //     })
      // })
      // getNewsDetail(this.cardIndex.id).then((res)=>{
      //     console.log(res)
      // })
      console.log(this.editListMode)
    },

    /**
     * @Author: YangXin
     * @Description: 上移动
     * @Date: 2024-08-15 14:52:12
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    onTheMove: function (item, index) {
      this.newsCategorysList.splice(index - 1, 0, item)
      this.newsCategorysList.splice(index + 1, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 下移动
     * @Date: 2024-08-15 14:52:33
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    underTheMove: function (item, index) {
      this.newsCategorysList.splice(index + 2, 0, item)
      this.newsCategorysList.splice(index, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 删除元素
     * @Date: 2024-08-15 14:58:00
     * @param {*} index
     * @return {*}
     */
     delBlockItemFunc: function () {
      this.linkList.splice(this.chooseindex, 1)
      if (this.linkList.length !== 0) {
        this.editListMode = this.linkList[0]
      }
    },

  
    /**
     * @Author: YangXin
     * @Description: 点击获取修改的item
     * @Date: 2024-08-08 09:07:28
     * @param {*} item
     * @return {*}
     */
    getItemFunc: function (item, index) {
      console.log(item)
      this.editListMode = item
      this.postId = item.id
      this.chooseIndex = index
      if (item.id == '') {
        this.addItemStatus = true
      } else {
        this.addItemStatus = false
      }
      // this.newsCategorysList[this.editListMode.id] =  this.editListMode
      // this.editListMode.id = item.id
      // this.editListMode.title = item.title
      // this.editListMode.images = item.src
    },

    /**
     * @Author: YangXin
     * @Description: 保存方法
     * @Date: 2024-08-08 10:17:20
     * @return {*}
     */
    savefunc: function () {
      let item = null
      this.newsCategorysList.map((res, index) => {
        if (res.id == this.editListMode.id && res.saveType == false) {
          this.newsCategorysList[index].saveType = true
        }else {
        }
      
      })
        if (this.cardChild.children && this.cardIndex?.type !== true) {
            this.cardChild.children[0].linkList = this.newsCategorysList
        } else if (this.cardChild.children == null||this.cardChild.children == '') {
        
          this.cardChild.children = []
          this.cardChild.children.push({"type": "views", linkList: this.newsCategorysList})
        }
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        // call(this)
    },

    editfunc: function (call) {
      let that = this

      updateNews(this.postId, {
        category_id: this.category_id,
        column_ids: [this.column_id],
        ...this.editListMode,
      }).then(res => {
        that.loadDataSource({ name: 'category', params: { id: res.category_id, column_id: that.column_id } })

        that.$message({
          message: '保存成功',
          type: 'success',
        })

        call(that)
      })
    },

    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-08 10:41:22
     * @return {*}
     */
    closeFunc: function () {
      this.$parent.urlEditDialogType = false
    },

    inputRadioFunc: function (val) {
      console.log(val)
    },
    // 上传图片
    onUpload:function(data) {
      console.log( this.editListMode.imageUrl)
        uploadImage(data.file, progress => {
            if (progress.total > 0) {
            progress.percent = (progress.loaded / progress.total) * 100
            }
            data.onProgress(progress)
        }).then(res => {
         
          this.editListMode.imageUrl = res.image_url
        
        })
    },

    ...mapActions({
      loadDataSource: 'schema/LoadDataSource',
    }),
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 80% !important;
  border-radius: 10px;
  background: #f0f0f0;
}

.flip-list-move {
  transition: transform 0.5s;
}
.badge {
  top: 0;
  // height: 20px;
  width: 40px;
  color: #409eff;
  position: absolute;
}
// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #9c2b2b !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.editMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .menuList {
    width: 30%;
    padding-top: 10px;
    padding: 10px;
    background: #ffffff;
    // border: 1px solid black;
    height: 725px;

    .navBox {
      cursor: pointer;
      height: 60px;
      width: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 8px;
      color: #0074ab;
      border: 1px solid #0074ab;
    }

    .navBox:hover,
    .itemBox:hover {
      background: #f0f0f0;
    }

    .bttomBox {
      padding-top: 10px;
      height: calc(100% - 60px);
      overflow-y: scroll !important;
      /* 或者 overflow-x: scroll; 根据需要选择 */
    }

    .itemBox {
      width: 97%;
      cursor: pointer;
      position: relative;
      height: 150px;
      // padding: 5px 8px;
      border-radius: 4px;
      display: flex;
      margin-bottom: 10px;
      border: 1px solid #C0C4CC;
    }

    .itemBox2 {
      background: #f0f0f0;

      // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
    }
  }

  .editMenu {
    padding-top: 10px;
    margin-left: 10px;
    background: #ffffff;
    height: 100%;
    width: 69%;

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}

.topMenu {
  top: 0;
  right: 0;
  position: absolute;
}
.startBox,
  .cardBox {
    height: 100%;
    display: flex;
    align-items: center;
    color: #000;

    // justify-content: space-around;
    .addCard {
      width: 122px;
      height: 100%;
      margin-right: 22px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .addCradImg {

        width: 90%;
        background-size: 100% 100%;
      }

    }


  }
</style>
